export default [
    {
        title: 'Gestion des campagnes',
        icon: 'HomeIcon',
        children: [
            {
                title: 'Gestion des campagnes',
                route: 'home',
            },
            {
                title: 'Gestion des SMS',
                route: 'sms',
            },
            {
                title: 'Gestion des metrics',
                route: 'manage-metrics',
            },
            {
                title: 'Lier des campagnes',
                route: 'link-campaigns',
            },
            {
                title: 'Parametrer les templates',
                route: 'templates-parameter',
            },
            {
                title: 'Associer un film',
                route: 'associate-creative',
            },
        ],
    },
    {
        title: 'Gestion des points de vente',
        route: 'listing-pos',
        icon: 'UsersIcon',
        children: [
            {
                title: 'Gestion des points de vente',
                route: 'listing-pos',
            },
            {
                title: 'Gestion des codes postaux',
                route: 'listing-zipcode',
            },
            {
                title: 'Gestion des factures',
                route: 'listing-invoices',
            },
            {
                title: 'Gestion de MAJ',
                route: 'import-data',
            },
        ],
    },
    {
        title: 'Gestion des users',
        route: 'listing-auth',
        icon: 'UsersIcon',
    },
    {
        title: 'Gérer les bannières',
        icon: 'ImageIcon',
        children: [
            {
                title: 'Création de bannières',
                route: 'banner-management',
            },
            {
                title: 'E-Dealer',
                route: 'e-dealer',
            },
            {
                title: 'Gestion des marques',
                route: 'banner-brand-management',
            },
        ],
    },
    {
        title: 'Gérer des potentiels',
        icon: 'ImageIcon',
        children: [
            {
                title: 'France TV',
                route: 'france-tv',
            },
            {
                title: 'TF1',
                route: 'tf1',
            },
        ],
    },
    {
        title: 'Gestion des bugs',
        route: 'bug-management',
        icon: 'AlertOctagonIcon',
    },
    {
        title: 'Déployer un projet',
        route: 'projet-deployment',
        icon: 'CloudIcon',
    },
    {
        title: 'Gestion des évenements',
        route: 'events',
        icon: 'CalendarIcon',
    },
    {
        title: 'Gestion des SQL',
        route: 'sql-manager',
        icon: 'TerminalIcon',
    },
    {
        title: 'Gestion des Prix',
        route: 'prices',
        icon: 'DollarSignIcon',
    },
    {
        title: 'Gestion des PMR',
        route: 'pmr',
        icon: 'ActivityIcon',
    },
    {
        title: 'Créative Ftv',
        route: 'ftv-creatives',
        icon: 'WindIcon',
    },
];
