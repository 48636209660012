<template>
    <b-row v-if="$route.meta.breadcrumb || $route.meta.pageTitle" class="content-header">
        <!-- Content Left -->
        <b-col class="content-header-left mb-2" cols="12" md="9">
            <b-row class="breadcrumbs-top">
                <b-col cols="12">
                    <h2 class="content-header-title float-left pr-1 mb-0">
                        {{ $route.meta.pageTitle }}
                    </h2>
                    <div class="breadcrumb-wrapper">
                        <b-breadcrumb>
                            <b-breadcrumb-item @click="handleHomeClick">
                                <feather-icon icon="HomeIcon" size="16" class="align-text-top" />
                            </b-breadcrumb-item>
                            <b-breadcrumb-item to="/" v-if="$route.name === 'details' && previousTable">{{ previousTable }}</b-breadcrumb-item>
                            <b-breadcrumb-item v-for="item in $route.meta.breadcrumb" :key="item.text" :active="item.active" :to="item.to">
                                {{ item.text }}
                            </b-breadcrumb-item>
                        </b-breadcrumb>
                    </div>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
    import { BBreadcrumb, BBreadcrumbItem, BRow, BCol } from 'bootstrap-vue';
    import Ripple from 'vue-ripple-directive';
    import { mapGetters } from 'vuex';

    export default {
        directives: {
            Ripple,
        },
        components: {
            BBreadcrumb,
            BBreadcrumbItem,
            BRow,
            BCol,
        },
        computed: {
            ...mapGetters({
                statusFilters: 'app/getStatusFilters',
                globalConfigPageCampaigns: 'app/getGlobalConfigPageCampaigns',
                statusSubFilter: 'app/getStatusSubFilter',
            }),

            previousTable() {
                if (this.statusFilters.includes('0') || this.statusFilters.includes('1')) {
                    if (this.globalConfigPageCampaigns.brandFilter.length === 0 && this.globalConfigPageCampaigns.mediaFilter.length === 0) {
                        return null;
                    } else return 'Liste des médias';
                } else if (this.statusFilters.includes('999')) {
                    // Les camagnes/medias en alerte :
                    if (this.globalConfigPageCampaigns.typeFilter.length === 0 && this.globalConfigPageCampaigns.brandFilter.length === 0 && this.globalConfigPageCampaigns.mediaFilter.length === 0) {
                        return null;
                    }
                    return ' Liste des alertes';
                } else if (this.statusFilters.includes('8') && this.statusSubFilter.length !== 0) {
                    return 'Liste des campagnes';
                } else {
                    if (this.globalConfigPageCampaigns.brandFilter.length === 0) {
                        return null;
                    } else return 'Liste des campagnes';
                }
            },
        },
        methods: {
            handleHomeClick() {
                if (this.$route.name === 'details') {
                    this.$store.dispatch('app/resetGlobalConfigPageCampaigns');
                }
                this.$router.push('/');
            },
        },
    };
</script>
