<template>
    <div class="navbar-container d-flex content align-items-center">
        <!-- Nav Menu Toggler -->
        <ul class="nav navbar-nav d-xl-none">
            <li class="nav-item">
                <b-link class="nav-link" @click="toggleVerticalMenuActive">
                    <feather-icon icon="MenuIcon" size="21" />
                </b-link>
            </li>
        </ul>

        <!-- Left Col -->

        <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
            <dark-Toggler class="d-none d-lg-block" />
        </div>

        <div class="support-container">
            <span>Support : </span>
            <div v-if="supportUser.length > 0" class="list">
                <span v-for="(user, index) in supportUser" :key="index">
                    <span v-if="user.firstname && user.firstname !== ''">
                        {{ user.firstname }}
                        <span v-if="hasNextNonEmptyFirstname(index, supportUser)">,</span>
                    </span>
                </span>
            </div>
        </div>
        <b-navbar-nav class="nav align-items-center ml-auto">
            <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
                <template #button-content>
                    <b-avatar size="40" variant="light-primary" badge class="badge-minimal" badge-variant="success" />
                </template>
                <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
                    <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
                    <span>Logout</span>
                </b-dropdown-item>
            </b-nav-item-dropdown>
        </b-navbar-nav>
    </div>
</template>

<script>
    import { BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BAvatar } from 'bootstrap-vue';
    import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue';
    import useJwt from '@/auth/jwt/useJwt';
    import { mapActions } from 'vuex';

    export default {
        data() {
            return {
                supportUser: {},
            };
        },

        components: {
            BLink,
            BNavbarNav,
            BNavItemDropdown,
            BDropdownItem,
            BAvatar,

            // Navbar Components
            DarkToggler,
        },

        props: {
            toggleVerticalMenuActive: {
                type: Function,
                default: () => {},
            },
        },

        created() {
            this.init();
        },

        methods: {
            ...mapActions({
                getSupportUser: 'app/getSupportUser',
            }),

            hasNextNonEmptyFirstname(index, users) {
                for (let i = index + 1; i < users.length; i++) {
                    if (users[i].firstname && users[i].firstname !== '') {
                        return true;
                    }
                }
                return false;
            },

            logout() {
                useJwt.logout();
                this.$router.push({ name: 'login' });
            },

            async init() {
                const today = new Date();

                const response = await this.getSupportUser();

                this.supportUser = response.data.filter((user) => {
                    const startDate = new Date(user.start_date);
                    const endDate = new Date(user.end_date);

                    return startDate <= today && today <= endDate;
                });
                if (this.supportUser.length === 0) {
                    this.errorMessage = 'Aucun utilisateur de support trouvé pour la date actuelle.';
                }
            },
        },
    };
</script>

<style scoped lang="scss">
    .support-container {
        display: flex;
        padding: 0 2rem;
        gap: 10px;

        .list {
            display: flex;
            gap: 5px;
        }
    }
</style>
